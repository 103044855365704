@import '~sass-mq';

h1, h2, h3, h4, h5, h6, p, span {
    font-family: 'Metropolis', 'Open Sans', -apple-system, sans-serif;
    color: $base-color;
    outline: none;

    &.ant-typography {
        color: $base-color;

        &.heading {
            color: $heading-color;
        }

        &.question {
            @include mq($until: mobile) {
                font-size: 28px;
            }
        }
    }
}

a, button {
    font-family: 'Metropolis', 'Open Sans', -apple-system, sans-serif;
    font-weight: 500;
    font-size: 15px;
}
