.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: $collapsible-background;
  border: 0px;
  border-radius: 2px;
}

.ant-spin-dot-item {
  background-color: $button-color;
}

.ant-popover-inner-content {
  & > .tooltip {
    color: $secondary-color;
    font-weight: 600;
  }
}

.ant-progress {
  .ant-progress-text {
    color: $base-color;
  }
  
  .ant-progress-inner {
    background-color: $base-color;
  }
  
  .ant-progress-bg, 
  .ant-progress-status-success .ant-progress-bg {
    background-color: $base-color-text;
  }
  
  .ant-progress-status-success .ant-progress-text {
    color: $base-color-text;
  }
}

.ant-collapse {
  .ant-collapse-header {
    font-family: 'Metropolis', 'Open Sans', -apple-system, sans-serif;
    font-weight: 600;
    font-size: 16px;
    
    .answer-true {
      color: $color-true;
    }
    .answer-false {
      color: $color-false;
    }
  }
  
  .ant-collapse-content-box {
    p {
      color: $base-color;
      font-weight: 500;
      font-size: 15px;
      margin: 0 0 5px 24px;
    }
  }

  &.ant-collapse-borderless {
    background-color: unset;
  }

  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: $base-color-2;
  }

  .ant-collapse-header .ant-collapse-arrow {
    color: $base-color-2;
  }
}

.ant-select {
  .ant-select-selector {
    background-color: unset;
    outline: none;
  }

  .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-select-arrow {
    color: $base-color-2;
    top: 50%;
    right: 25px;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-content {
    .difficulty-level {
      color: $heading-color;
    }
  }
}
