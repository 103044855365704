@import '~sass-mq';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
  font-size: 12px;
  color: $base-color-text-2;

  a {
    background-color: unset;
    height: auto;
    width: auto;
    display: inline;
    padding: 13px 0 10px 7px;
    color: $base-color-text-2;

    @include mq($until: mobile) {
      padding: 5px;
      font-size: 12px;
    }
  }
}
