@import '~sass-mq';

#root {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app {
  padding: 0 8%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include mq($until: desktop) {
    justify-content: center;
  }
}

.app-wrapper {
  padding: 100px 0;
  position: relative;
  max-width: 600px;
  width: 60%;
  min-height: 450px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq($until: desktop) {
    width: 100%;
  }

  @include mq($until: mobile) {
    padding: 50px 0;
  }

  & > .ant-spin-spinning, 
  & > .restart {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .question-body {
    min-height: 230px;
  }

  .share-wrapper {
    margin: $default-spacing 0;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .progress-bar {
    margin-bottom: $default-spacing;
  }

  .results-wrapper {
    .collapsible {
      max-height: 400px;
      margin-top: $default-spacing;
      overflow: auto;
      border-top: 1px solid $base-color-2;
      border-bottom: 1px solid $base-color-2;
    }
  }
}