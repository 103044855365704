$base-color: #dde0e4;
$base-color-2: #ffffff;
$secondary-color: #25395f;
$button-color: #34afa7;
$button-color-hover: #14c6bb;
$button-color-false: #f52905;
$button-color-hover-false: #f14f1e;
$button-share: #1da1f2;

$base-color-text: #64ffdb;
$base-color-text-2: #a5b8d8;
$heading-color: #14c6bb;

$color-true: #14c6bb;
$color-false: #f52905;

$collapsible-background: #f7f7f7;
$difficulty-easy: #52c41a;
$difficulty-hard: #f52905;
